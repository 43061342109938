import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { FACET_FILTER_HIDE_GROUP_NAME, RANGE_FACET_VALUE_SEPARATOR } from '../../../constants/search';
import { TrackedEvent } from '../../../helpers/analytics/event-types';
import { trackSearchEvent } from '../../../helpers/search-helper/search-analytics.helper';
import {
	facetGroupIsQuickShip,
	getFacetDisplayValue,
	getFacetGroupDisplayName,
	requestFacetsToSelectedFacetGroups,
	isRangeFacetGroup
} from '../../../helpers/search-helper/search-helper';
import { useSearchResults, UseSearchResultsPayload } from '../../../hooks/apollo/search/search.hooks';
import { FacetGroup, FacetWithSource, SelectedFacet, SelectedFacetGroup } from '../../../types/search.types';
import { TextButton } from '../../buttons';
import { Popover } from '../../common-components/popover/popover.component';
import { CloseIcon, HelpCircleIcon, QuickShipIcon } from '../../svg/icons.component';

function shouldShowGroupNameAsValue(group: SelectedFacetGroup, facet: SelectedFacet): boolean {
	return facet.value === 'true' && !facetGroupIsQuickShip(group);
}

function shouldHideGroupName(group: SelectedFacetGroup): boolean {
	return FACET_FILTER_HIDE_GROUP_NAME.has(group.name.toLowerCase());
}

/**
 * Return an array of indexes for selectedFacetGroups representing the traversal order for rendering.
 * Uses regular facet group list order. If a group is not found, sort it to the end, preserving original order.
 */
function getSelectedFacetRenderOrder(
	selectedFacetGroups: SelectedFacetGroup[],
	facetGroups: FacetGroup[],
	isNewLookAndFeel: boolean
): number[] {
	const groupIndexes = facetGroups.reduce((result, group, ix) => {
		result[group.id] = ix;
		return result;
	}, {});
	return isNewLookAndFeel
		? selectedFacetGroups
				.map((group, ix) => ({ group, ix }))
				.sort((a, b) => (groupIndexes[a.group.id] ?? a.ix + 1000) - (groupIndexes[b.group.id] ?? b.ix + 1000))
				.map(({ ix }) => ix)
		: selectedFacetGroups.map((_, ix) => ix);
}

const AutoAppliedFacetHelp: FunctionComponent = () => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<Popover
			popOverClassName="w5 f6"
			isVisible={isOpen}
			setIsVisible={setIsOpen}
			toggleElement={
				<div className="flex items-center pa1 pointer">
					<HelpCircleIcon className="theme-secondary" />
				</div>
			}>
			This filter has been automatically applied based on your search terms.
		</Popover>
	);
};

const FacetFilterPill: FunctionComponent<{
	facet: SelectedFacet;
	group: SelectedFacetGroup;
	query: string;
	removeFacet: (facet: FacetWithSource) => void;
	createGTMEvent: UseSearchResultsPayload['createGTMEvent'];
	isNewLookAndFeel?: boolean;
}> = ({ facet, group, query, removeFacet, createGTMEvent, isNewLookAndFeel }) => {
	const facetDisplayValue = getFacetDisplayValue(facet, group);

	const removeClick = () => {
		removeFacet({ id: facet.facetId, value: facet.value, sourceFacetId: facet.sourceFacetId });
		void trackSearchEvent(createGTMEvent(TrackedEvent.FACET_INTERACTION, { query, facet, group, applied: false }));
	};

	const isQuickShip = facetGroupIsQuickShip(group);
	const wrapperClasses = isNewLookAndFeel
		? `pv2 pl3 pr2 f5 br-pill fade-in lh-title b--theme-grey-light`
		: 'pa1 b--theme-grey-light mt2 mr3';

	let value = facetDisplayValue;
	let header = '';
	if (isNewLookAndFeel) {
		if (shouldShowGroupNameAsValue(group, facet)) {
			value = getFacetGroupDisplayName(group);
		} else if (!shouldHideGroupName(group)) {
			header = getFacetGroupDisplayName(group);
		}
	}

	return (
		<div className="dib">
			<div className={`ba items-center inline-flex ${wrapperClasses}`}>
				{isNewLookAndFeel ? (
					<>
						{header && <span className="ml1">{header}:</span>}
						<span className={`ml1 ${isQuickShip ? 'flex items-center' : ''}`}>
							{isQuickShip && <QuickShipIcon className="f2 h1 mr1 theme-primary omni-theme-secondary" />}
							{value}
						</span>
					</>
				) : (
					<span className="pa1">{facetDisplayValue}</span>
				)}
				<div className={isNewLookAndFeel ? '' : 'ml3 bl b--theme-grey-lighter'}>
					{facet.autoApplied ? (
						<AutoAppliedFacetHelp />
					) : (
						<TextButton
							onClick={removeClick}
							color={isNewLookAndFeel ? 'grey-darker' : 'grey'}
							ariaLabel={`Remove filter: ${group.name}: ${facetDisplayValue}`}>
							<div className={`flex items-center ${isNewLookAndFeel ? 'pa2' : 'pa1'}`}>
								<CloseIcon className={`f5 ${isNewLookAndFeel ? 'theme-primary' : ''}`} />
							</div>
						</TextButton>
					)}
				</div>
			</div>
		</div>
	);
};

export type FacetFilterListProps = {
	useResults?: () => UseSearchResultsPayload;
	className?: string;
	hideClearAll?: boolean;
};

export const FacetFilterList: FunctionComponent<FacetFilterListProps> = ({
	useResults = useSearchResults,
	className = '',
	hideClearAll
}) => {
	const { query, results, previousResults, clearFacets, removeFacet, loading, isNewLookAndFeel, createGTMEvent, request } = useResults();
	const renderResults = loading ? previousResults : results;
	const facetGroups = renderResults?.facetGroups ?? [];
	let selectedFacetGroups = renderResults?.selectedFacetGroups ?? [];

	if (isNewLookAndFeel && loading) {
		selectedFacetGroups = requestFacetsToSelectedFacetGroups(request.facetFilter, facetGroups) ?? selectedFacetGroups;
	}

	const hasRemovableFacets = selectedFacetGroups.some((g) => g.facets.some((f) => !f.autoApplied));
	const clearAllFacets = () => {
		selectedFacetGroups.forEach((group) => {
			group.facets
				.filter((facet) => !facet.autoApplied)
				.forEach((facet) => {
					void trackSearchEvent(createGTMEvent(TrackedEvent.FACET_INTERACTION, { query, facet, group, applied: false }));
				});
		});
		clearFacets();
	};

	// Render selected facet groups ordered the same as facet groups.
	const groupSortOrder = getSelectedFacetRenderOrder(selectedFacetGroups, facetGroups, isNewLookAndFeel);

	return selectedFacetGroups.length ? (
		<div className={isNewLookAndFeel ? `flex flex-wrap ga2 ${className}` : ''}>
			{!hideClearAll && hasRemovableFacets && (
				<TextButton automationHook="clear-all-facets" onClick={clearAllFacets} className={isNewLookAndFeel ? 'ph1 pv3 mr1' : ''}>
					{!isNewLookAndFeel ? 'x ' : ''}Clear All
				</TextButton>
			)}
			{groupSortOrder.map((groupIndex) => {
				const group = selectedFacetGroups[groupIndex];
				const isRangeGroup = isRangeFacetGroup(group);
				let rangeFacet, rangeMin, rangeMax;

				if (isRangeGroup) {
					rangeMin = group.range?.min;
					rangeMax = group.range?.max;
					rangeFacet = {
						facetId: group.facets[0].facetId,
						value: `${rangeMin}${RANGE_FACET_VALUE_SEPARATOR}${rangeMax}`,
						autoApplied: group.facets[0].autoApplied
					};
				}

				const selectedFacets = !isRangeGroup ? group.facets : [];

				// Render facets ordered by value.
				const facetOrder = isNewLookAndFeel
					? selectedFacets
							.map((facet, ix) => ({ facet, ix }))
							.sort((a, b) => a.facet.value.localeCompare(b.facet.value))
							.map(({ ix }) => ix)
					: selectedFacets.map((_, ix) => ix);

				return (
					<FacetFilterPillGroupWrapper key={group.id} isNewLookAndFeel={isNewLookAndFeel}>
						{!isNewLookAndFeel && <span className="fw7 mh2">{getFacetGroupDisplayName(group)}:</span>}
						{isRangeGroup ? (
							<FacetFilterPill
								key={`${group.id}:${rangeMin}-${rangeMax}`}
								facet={rangeFacet}
								group={group}
								query={query}
								removeFacet={removeFacet}
								isNewLookAndFeel={isNewLookAndFeel}
								createGTMEvent={createGTMEvent}
							/>
						) : (
							facetOrder.map((facetIndex) => {
								const facet = selectedFacets[facetIndex];
								return (
									<FacetFilterPill
										key={`${facet.facetId}:${facet.value}`}
										facet={facet}
										group={group}
										query={query}
										removeFacet={removeFacet}
										isNewLookAndFeel={isNewLookAndFeel}
										createGTMEvent={createGTMEvent}
									/>
								);
							})
						)}
					</FacetFilterPillGroupWrapper>
				);
			})}
		</div>
	) : null;
};

/**
 * Conditionally wrap facet pills in a div if not using the new look and feel.
 */
const FacetFilterPillGroupWrapper: FunctionComponent<PropsWithChildren<{ isNewLookAndFeel: boolean }>> = ({
	isNewLookAndFeel,
	children
}) => {
	return isNewLookAndFeel ? children : <div className="dib">{children}</div>;
};
